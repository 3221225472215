// @flow

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Text from 'atomize/dist/atoms/text/Text';
import Container from 'atomize/dist/atoms/grid/Container';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Div from 'atomize/dist/atoms/div/Div';
import Button from 'atomize/dist/atoms/button/Button';

import {BsCheckCircle as CheckIcon} from 'react-icons/bs';

import Box from '../components/checkout/Box';

import HtmlHead from '../components/HtmlHead';

import {
  isInitialState
} from '../redux/selectors/checkoutSelectors';

function Header() {
  return (
    <>
      <Text textColor='success600' textAlign='center'><CheckIcon size='6rem' /></Text>
      <Text tag='h1' textColor='black' textSize='display1' textAlign='center'>Mokėjimas atliktas</Text>
    </>
  )
}


export default function SuccessView({location} : Object) {

  const {state} = location;
  const email = !!state ? state.email : '';

  const dispatch = useDispatch();
  const freshCheckoutState = useSelector(isInitialState);

  useEffect(() => {
    // a hacky workaround - flickers if dispatched from PaymentStep
    if (!freshCheckoutState) {
      dispatch({type: 'PROCESS_PAYMENT_SUCCESS'});
    }
  }, []);

  return (
    <>
      <HtmlHead title='Ačiū, kad naudojatės bukitesveiki.lt.' meta={{robots: 'noindex'}} />
      <Container>
        <Div p='2rem' rounded='lg' bg='gray300'>
          <Header />
          <Text textAlign='center' p={{t: '2rem'}}>
            Ačiū, kad naudojatės bukitesveiki.lt. Netrukus gausite užsakymo patvirtinimą elektroniniu paštu <Text tag='span' textWeight='600'>{email}</Text>.
          </Text>
        </Div>
      </Container>
    </>
  );
}
